@import (reference) '@/style/public';

.messageCenterContainer {
    height: 100%;
    .flex(0);
    justify-content: space-between;

    .leftMenu {
        width: 177px;
        height: 100%;
        .flexc(0);
        border-right: 1px solid var(--Color-Border-Accent-Neutral-Subtle, #EEF1F5);

        .fixedPart {
            padding: 16px 20px 20px;
            .flex(0);
            color: var(--Color-Text-Neutral-Default, #222B40);
            font-size: 16px;
            font-weight: 500;
        }

        .fixedContent {
            padding: 8px 12px 20px;
            .flexc(8);
            li {
                cursor: pointer;
                border-radius: 2px;
                transition: background .2s;
                &.removeHoverStyle{
                    cursor:default
                }
                &:not(.removeHoverStyle):hover {
                    background: var(--Color-Bg-Neutral-Subtler-Hovered, #EEF1F5);
                }

                &.active {
                    background: var(--Color-Bg-Neutral-Subtler-Hovered, #EEF1F5);
                }

                padding:7px 12px;
                height:22px;
                .flex(6);

                .title {
                    color: var(--Color-Text-Neutral-Default, #222B40);
                    font-weight: 500;
                }

                &.childPart {
                    padding-left: 34px;

                    .title {
                        color: var(--Color-Text-Subtle, #58657C);
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .rightList {
        width: calc(100% - 180px);
        height: 100%;
        .flexc(0);
        .list{
            padding-left:24px;
            padding-right:32px;
            .readed{
                color: var(--Color-Text-Subtler, #828FA1)!important;
                &:hover{
                    color: var(--Color-Text-Subtler, #828FA1)!important;
                }
            }
            li{
                cursor:pointer;
                padding:16px 0;
                border-bottom:1px solid  var(--Color-Border-Accent-Neutral-Subtle, #EEF1F5);
                &:hover{
                    .left{
                        color: var(--Color-Text-Brand-Hovered, #BD2E3C);
                    }
                }
            }
            .title{
                .flex(0);
                .height(24);
                justify-content: space-between;
                .left{
                    color: var(--Color-Text-Neutral-Default, #222B40);
                    transition: color .2s;
                    .flex(4);
                    width:calc(100% - 130px);
                    span{
                        display:block;
                        .overflow();
                        &.addTag{
                            width: calc(100% - 65px)
                        }
                    };
                }
                .right{
                    color: var(--Color-Text-Subtler, #828FA1);
                    font-size:12px;
                }
            }
            .content{
                .flex(20);
                align-items: flex-start;
                .contentDetail{
                    width:calc(100% - 80px);
                    color: var(--Color-Text-Subtle, #58657C);
                    line-height:22px;
                    padding:1px 0;
                    transition: height .3s;
                    overflow:hidden;
                    .showPart{
                        .overflow();
                    }
                }
                .Linkstyle{
                    .flex(6);
                    padding:2px 8px;
                    color: var(--Color-Text-Neutral-Default, #222B40);
                    font-size:12px;
                    line-height:20px;
                    border-radius:2px;
                    &:hover{
                        background: var(--Color-Bg-Neutral-Subtler-Hovered, #EEF1F5);
                    }
                }
            }
        }
    }

    .fixedPart {
        height: 60px;
        box-sizing: border-box;flex-shrink: 0;
    }

    .fixedContent {
        overflow: auto;
        height: calc(100% - 120px)
    }

    .topHeader {
        padding: 8px 16px 4px 24px;
        display: flex;
        justify-content: space-between;
    }

    .leftMenuEnContainer{
        &.selected {
            &:after {
                left: 60px;
            }
        }
        &:after {
            content: "";
            width: 55px;
            height: 2px;
            background: var(--Color-Bg-Brand-Bold-Default, #AD0C24);
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            position: absolute;
            left: 0;
            bottom: 0;
            transition: left .2s;
        }
    }
    .leftMenuContainer {
        .flex(6);
        position: relative;
        &.selected {
            &:after {
                left: 54px;
            }
        }
        .topMenuTemp {
            width: 48px;
            color: var(--Color-Icon-Subtle, #58657C);
            font-size: 16px;
            .height(48);
            text-align: center;

            &.active {
                color: var(--Color-Text-Neutral-Default, #222B40);
                font-weight: 500;
            }
        }

        &:after {
            content: "";
            width: 48px;
            height: 2px;
            background: var(--Color-Bg-Brand-Bold-Default, #AD0C24);
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            position: absolute;
            left: 0;
            bottom: 0;
            transition: left .2s;
        }
    }

    .rightAllRead {
        .flex(12);

    }
    .bottomPage{
        border-top:1px solid var(--Color-Border-Accent-Neutral-Subtle, #EEF1F5);
        padding:16px 24px 20px;
        .flex(0);
        justify-content: space-between;
        // height:24px;
        .left{
            color: var(--Color-Text-Neutral-Default, #222B40);
            .height(24);
        }
        .right{
            .flex(4);
            .pageStyle{
                min-width: 24px;
                height:24px;
                box-sizing:border-box;
                padding:5px;
                border-radius:2px;
                border:1px solid var(--Color-Border-Input-Default, #E0E4E9);
                background: var(--Color-Bg-Input-Subtle, #F5F7FA);
                color: var(--Color-Text-Subtle, #58657C);
                font-size:12px;
                text-align: center;
                line-height:12px;
                &.curr{
                    color:var(--Color-Text-Neutral-Default, #222B40);
                }
                &.disabled{
                    color:#AFB7C2;
                    cursor:not-allowed;
                }
            }
        }
    }

}
:global{
    .messageCenterLoading{
        width:100%;
        height:480px;
        position:relative;
        .arco-spin-icon{
            position:absolute;
            left:50%;
            top:50%;
            transform: translate(-100%, -100%);
        }
    }
}