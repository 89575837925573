:root {

    --White: #ffffff;
    --White001: #ffffff1a;
    --White005: #ffffff4d;
    --White007: #ffffffb2;
    --Blue-002: #001f4d05;
    --Blue-01: #e8f7ff;
    --Blue-02: #bfe4f6;
    --Blue-03: #8ec8ec;
    --Blue-04: #62aee0;
    --Blue-05: #3388ca;
    --Blue-06: #106cbd;
    --Blue-07: #0b56a1;
    --Blue-08: #064184;
    --Blue-09: #022f69;
    --Blue-10: #001f4d;
    --Green-01: #f3fff9;
    --Green-02: #dcfaed;
    --Green-03: #bbf1d9;
    --Green-04: #76dbb1;
    --Green-05: #25a06d;
    --Green-06: #1f845a;
    --Green-07: #186a48;
    --Green-08: #155c3f;
    --Green-09: #0f422e;
    --Green-10: #09281b;
    --Neutral-0005: #0000000d;
    --Neutral-004: #222b4066;
    --Neutral-007: #222b40b2;
    --Neutral-01: #f5f7fa;
    --Neutral-02: #eef1f5;
    --Neutral-03: #e0e4e9;
    --Neutral-04: #afb7c2;
    --Neutral-05: #828fa1;
    --Neutral-06: #515d73;
    --Neutral-07: #3f4960;
    --Neutral-08: #222b40;
    --Neutral-09: #192133;
    --Neutral-10: #0e1424;
    --Red-01: #fff4f2;
    --Red-02: #ffe9e7;
    --Red-03: #ffdbd8;
    --Red-04: #ffafac;
    --Red-05: #bd2e3c;
    --Red-06: #ad0c24;
    --Red-07: #950820;
    --Red-08: #7d041c;
    --Red-09: #650219;
    --Red-10: #4d0014;
    --Yellow-02: #fef2c7;
    --Yellow-03: #feecab;
    --Yellow-04: #fee690;
    --Yellow-05: #fcc706;
    --Yellow-06: #e2b203;
    --Yellow-07: #cba004;
    --Yellow-08: #b58e03;
    --Yellow-09: #876b02;
    --Yellow-10: #443500;
    --Yellow-Yellow01: #fff9e3;



    --Color-Bg-Disabeld: var(--Neutral-02);
    --Color-Bg-Accent-Blue-Bolder: var(--Blue-06);
    --Color-Bg-Accent-Blue-Opacity: var(--Blue-002);
    --Color-Bg-Accent-Blue-Subtlest: var(--Blue-01);
    --Color-Bg-Accent-Green-Bolder: var(--Green-06);
    --Color-Bg-Accent-Green-Sublest: var(--Green-01);
    --Color-Bg-Accent-Neutral-Bolder: var(--Neutral-08);
    --Color-Bg-Accent-Neutral-Subtle: var(--Neutral-04);
    --Color-Bg-Accent-Neutral-Subtle2: var(--Neutral-03);
    --Color-Bg-Accent-Neutral-Subtler: var(--Neutral-01);
    --Color-Bg-Accent-Neutral-Subtlest: var(--White);
    --Color-Bg-Accent-Red-Bolder: var(--Red-06);
    --Color-Bg-Accent-Red-Subtlest: var(--Red-01);
    --Color-Bg-Accent-Yellow-Bolder: var(--Yellow-06);
    --Color-Bg-Accent-Yellow-Subtler: var(--Yellow-02);
    --Color-Bg-Accent-Yellow-Subtlest: var(--Yellow-Yellow01);
    --Color-Bg-Brand-Bold-Default: var(--Red-06);
    --Color-Bg-Brand-Bold-Hovered: var(--Red-05);
    --Color-Bg-Brand-Bold-Pressed: var(--Red-08);
    --Color-Bg-Brand-Subtlest-Default: var(--Red-01);
    --Color-Bg-Brand-Subtlest-Hovered: var(--Red-02);
    --Color-Bg-Brand-Subtlest-Pressed: var(--Red-03);
    --Color-Bg-Danger-Default: var(--Red-06);
    --Color-Bg-Danger-Hovered: var(--Red-05);
    --Color-Bg-Danger-Pressed: var(--Red-08);
    --Color-Bg-Danger-Subtlest-Default: var(--Red-01);
    --Color-Bg-Danger-Subtlest-Hovered: var(--Red-02);
    --Color-Bg-Danger-Subtlest-Pressed: var(--Red-03);
    --Color-Bg-Info-Default: var(--Blue-06);
    --Color-Bg-Info-Hovered: var(--Blue-05);
    --Color-Bg-Info-Pressed: var(--Blue-07);
    --Color-Bg-Info-Subtlest-Default: var(--Blue-01);
    --Color-Bg-Info-Subtlest-Hovered: var(--Blue-02);
    --Color-Bg-Info-Subtlest-Pressed: var(--Blue-03);
    --Color-Bg-Input-Hovered: var(--Neutral-02);
    --Color-Bg-Input-Pressed: var(--Neutral-03);
    --Color-Bg-Input-Subtle: var(--Neutral-01);
    --Color-Bg-Input-Subtler: var(--White);
    --Color-Bg-Neutral-Bold-Default: var(--Neutral-08);
    --Color-Bg-Neutral-Bold-Disabled: var(--Neutral-004);
    --Color-Bg-Neutral-Bold-Hovered: var(--Neutral-007);
    --Color-Bg-Neutral-Bold-Pressed: var(--Neutral-10);
    --Color-Bg-Neutral-Subtler-Default: var(--Neutral-01);
    --Color-Bg-Neutral-Subtler-Hovered: var(--Neutral-02);
    --Color-Bg-Neutral-Subtler-Pressed: var(--Neutral-03);
    --Color-Bg-Neutral-Subtlest-Default: var(--White);
    --Color-Bg-Neutral-Subtlest-Hovered: var(--Neutral-01);
    --Color-Bg-Neutral-Subtlest-Pressed: var(--Neutral-02);
    --Color-Bg-Selected-Default: var(--Red-06);
    --Color-Bg-Selected-Disabled: var(--Red-04);
    --Color-Bg-Selected-Hovered: var(--Red-05);
    --Color-Bg-Selected-Pressed: var(--Red-08);
    --Color-Bg-Success-Default: var(--Green-06);
    --Color-Bg-Success-Hovered: var(--Green-05);
    --Color-Bg-Success-Pressed: var(--Green-07);
    --Color-Bg-Success-Subtlest-Default: var(--Green-01);
    --Color-Bg-Success-Subtlest-Hovered: var(--Green-02);
    --Color-Bg-Success-Subtlest-Pressed: var(--Green-03);
    --Color-Bg-Warning-Default: var(--Yellow-06);
    --Color-Bg-Warning-Hovered: var(--Yellow-05);
    --Color-Bg-Warning-Pressed: var(--Yellow-07);
    --Color-Bg-Warning-Subtlest-Default: var(--Yellow-Yellow01);
    --Color-Bg-Warning-Subtlest-Hovered: var(--Yellow-02);
    --Color-Bg-Warning-Subtlest-Pressed: var(--Yellow-03);
    --Color-Border-Card-Default: var(--Neutral-03);
    --Color-Border-Card-Hovered: var(--White);
    --Color-Border-Accent-Blue-Bolder: var(--Blue-06);
    --Color-Border-Accent-Blue-Subtler: var(--Blue-05);
    --Color-Border-Accent-Blue-Subtlest: var(--Blue-02);
    --Color-Border-Accent-Green-Bolder: var(--Green-06);
    --Color-Border-Accent-Green-Sublest: var(--Green-03);
    --Color-Border-Accent-Green-Subtler: var(--Green-05);
    --Color-Border-Accent-Neutral-Bolder: var(--Neutral-03);
    --Color-Border-Accent-Neutral-Inverse: var(--White001);
    --Color-Border-Accent-Neutral-Inverse-Black: var(--Neutral-0005);
    --Color-Border-Accent-Neutral-Subtle: var(--Neutral-02);
    --Color-Border-Accent-Neutral-Subtler: var(--Neutral-01);
    --Color-Border-Accent-Neutral-Subtlest: var(--White);
    --Color-Border-Accent-Neutral-S-Bolder: var(--Neutral-04);
    --Color-Border-Accent-Red-Bolder: var(--Red-06);
    --Color-Border-Accent-Red-Subtler: var(--Red-04);
    --Color-Border-Accent-Red-Subtlest: var(--Red-03);
    --Color-Border-Accent-Yellow-Bolder: var(--Yellow-09);
    --Color-Border-Accent-Yellow-Sublest: var(--Yellow-03);
    --Color-Border-Accent-Yellow-Subtler: var(--Yellow-08);
    --Color-Border-Input-Default: var(--Neutral-03);
    --Color-Border-Input-Hovered: var(--Red-06);
    --Color-Border-Input-Pressed: var(--Red-08);
    --Color-Border-Selecated-Default: var(--Red-06);
    --Color-Border-Selecated-Hovered: var(--Red-05);
    --Color-Border-Selecated-Pressed: var(--Red-08);
    --Color-Icon-Disabled: var(--Neutral-04);
    --Color-Icon-Inverse: var(--White);
    --Color-Icon-Inverse-Disabled: var(--White005);
    --Color-Icon-Inverse-Subtle: var(--White007);
    --Color-Icon-Subtle: var(--Neutral-06);
    --Color-Icon-Subtler: var(--Neutral-05);
    --Color-Icon-Subtlest: var(--Neutral-04);
    --Color-Icon-Accent-Blue-Bolder: var(--Blue-08);
    --Color-Icon-Accent-Blue-Subtle: var(--Blue-06);
    --Color-Icon-Accent-Green-Bolder: var(--Green-08);
    --Color-Icon-Accent-Green-Subtle: var(--Green-06);
    --Color-Icon-Accent-Green-Subtler: var(--Green-05);
    --Color-Icon-Accent-Red-Bolder: var(--Red-08);
    --Color-Icon-Accent-Red-Subtle: var(--Red-06);
    --Color-Icon-Accent-Yellow-Bolder: var(--Yellow-09);
    --Color-Icon-Accent-Yellow-Subtle: var(--Yellow-06);
    --Color-Icon-Brand-Default: var(--Red-06);
    --Color-Icon-Brand-Hovered: var(--Red-05);
    --Color-Icon-Brand-Pressed: var(--Red-08);
    --Color-Icon-Neutral-Default: var(--Neutral-08);
    --Color-Icon-Neutral-Hovered: var(--Neutral-05);
    --Color-Icon-Neutral-Pressed: var(--Neutral-10);
    --Color-Link-Default: var(--Neutral-06);
    --Color-Link-Disabled: var(--Neutral-04);
    --Color-Link-Red-Default: var(--Red-06);
    --Color-Link-Red-Hovered: var(--Red-05);
    --Color-Link-Red-Pressed: var(--Red-08);
    --Color-Text-Danger: var(--Red-06);
    --Color-Text-Disabled: var(--Neutral-04);
    --Color-Text-Info: var(--Blue-07);
    --Color-Text-Invers-Subtle: var(--White007);
    --Color-Text-Inverse: var(--White);
    --Color-Text-Inverse-Disabled: var(--White005);
    --Color-Text-Subtle: var(--Neutral-06);
    --Color-Text-Subtler: var(--Neutral-05);
    --Color-Text-Subtlest: var(--Neutral-04);
    --Color-Text-Success: var(--Green-07);
    --Color-Text-Warning: var(--Yellow-09);
    --Color-Text-Accent-Blue: var(--Blue-08);
    --Color-Text-Accent-Green: var(--Green-08);
    --Color-Text-Accent-Neutral: var(--Neutral-08);
    --Color-Text-Accent-Red: var(--Red-06);
    --Color-Text-Accent-Yellow: var(--Yellow-09);
    --Color-Text-Brand-Default: var(--Red-06);
    --Color-Text-Brand-Hovered: var(--Red-05);
    --Color-Text-Brand-Pressed: var(--Red-08);
    --Color-Text-Neutral-Default: var(--Neutral-08);
    --Color-Text-Neutral-Hovered: var(--Neutral-05);
    --Color-Text-Neutral-Pressed: var(--Neutral-10);
    --Elevation-Surface-Subtler: var(--Neutral-01);
    --Elevation-Surface-Subtlest: var(--White);
    --Elevation-Surface-Overlay-Hovered: var(--Neutral-02);
    --Elevation-Surface-Overlay-Pressed: var(--Neutral-03);
    --Elevation-Surface-Overlay-Subtle: var(--White);
    --Elevation-Surface-Overlay-Subtler: var(--Neutral-01);
    --Elevation-Surface-Sunken-Subtle: var(--White);
    --Elevation-Surface-Sunken-Subtler: var(--Neutral-01);


    --Pop-Height-S: 450px;
    --Pop-Weight-S: 460px;
    --Pop-Weight-M: 540px;
    --Pop-Height-M: 550px;
    --Pop-Weight-L: 700px;

}