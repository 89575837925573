@import (reference) '@/style/public';

.publicEntranceContainer {
    width: 52px;
    height: auto;
    position: fixed;
    right: 4px;
    bottom: 80px;
    .flexc(12);
    z-index: 120;

.emailShow{
    color:inherit;
    font-size:inherit;
    transition:color .3s;
    cursor:pointer;
    &:hover{
        color: var(--Color-Link-Red-Default, #AD0C24);
    }
}
    .top,
    .bottom {
        border-radius: 2px;
        border: 2px solid var(--Color-Border-Accent-Neutral-Subtlest, #FFF);
        background: linear-gradient(180deg, #F3F5F8 0%, #FFF 100%);
        box-shadow: 6px 6px 30px 0px rgba(2, 47, 105, 0.07);
        .flexc(4);
    }

    .entranceTemp {
        width: 24px;
        height: 24px;
        padding: 12px;
        cursor: pointer;
        color: #222B40;
        transition: all .2s;
        position: relative;

        // .flex(0);
        &:hover {
            background: var(--Color-Bg-Brand-Bold-Hovered, #BD2E3C);
            color: #fff
        }

        path,
        svg {
            stroke: currentColor;
        }

        circle {
            fill: currentColor;
            stroke: currentColor;
        }

        &.specialSvgStyles {
            path {
                stroke: none;
                fill: currentColor;
            }
        }

        &:hover {
            .contentContainer {
                transform: scale(1);
            }
        }

        .contentContainer {

            transform: scale(0);
            transform-origin: right top;
            position: absolute;
            right: 48px;
            top: 0;
            // overflow: hidden;
            transition: all .2s;
            cursor:auto;

            >div {
                border-radius: 4px;
                border: 2px solid var(--Color-Border-Accent-Neutral-Subtlest, #FFF);
                background: linear-gradient(180deg, #F3F5F8 0%, #FFF 100%);
                box-shadow: 8px 8px 30px 0px rgba(39, 70, 120, 0.15);
                margin-right: 10px;
                padding: 24px;
            }

            .wechatCode {
                width: 182px;
                .flexc(12);
                align-items: center;

                img {
                    width: 102px;
                    height: 102px;
                }

                .codeNotice {
                    color: var(--Color-Text-Subtle, #58657C);
                    text-align: center;
                    line-height: 22px;
                }

                .topTitle {
                    color: var(--Color-Text-Neutral-Default, #222B40);
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    // text-align:left;
                    width:100%;
                }

                .productList {
                    .flexc(4);
                    width:100%;
                    li {
                        color: var(--Color-Text-Neutral-Default, #222B40);
                        line-height: 22px;
                        transition:color .3s;
                        cursor:pointer;
                        &:hover{
                            color: var(--Color-Link-Red-Default, #AD0C24);
                        }
                    }
                }
            }

            .phoneContent {
                width: 205px;

                .topPart {
                    color: var(--Color-Text-Subtle, #58657C);
                    line-height: 22px;
                }

                .bottomPart {
                    .flex(0);
                    justify-content: space-between;
                    color: var(--Color-Text-Neutral-Default, #222B40);
                }
            }
        }
    }
}