@import (reference) '@/style/public';

.redqueenModalContainer {
    &.arco-modal {

        padding: 24px 0 0 !important;

        &.redqueenAlertModal {
            .arco-modal-content {
                min-height: 0;
                margin-top:22px;
                color: var(--Color-Text-Subtle, #58657C);
                line-height:22px;
            }
        }

        .arco-modal-header {
            padding: 0 32px 8px;
            border-bottom: none;
            .height(30);

            .arco-modal-title {
                text-align: left;

                span {
                    color: var(--Color-Text-Neutral-Default);
                    font-size: 20px;
                    font-weight: 500;
                }
            }
        }

        .arco-modal-content {
            padding: 0 32px;
            margin: 12px 0;
            min-height: 276px;
            max-height: 390px;
            overflow: auto;
            background-color: var(--color-bg-3);
            /* 隐藏浏览器默认滚动条 */
            ::-webkit-scrollbar {
                display: none;
            }

            /* 滚动条轨道 */
            ::-webkit-scrollbar-track {
                background-color: transparent;
            }

            /* 滚动条手柄 */
            ::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.13);
                border-radius: 7px;
            }

            /* 鼠标悬停时滚动条手柄 */
            ::-webkit-scrollbar-thumb:hover {
                background-color: rgba(0, 0, 0, 0.13);
            }

            /* 设定滚动条宽度 */
            scrollbar-width: thin;

            /* 针对 Firefox */
            scrollbar-color:rgba(0, 0, 0, 0.13);

            /* 针对 WebKit 浏览器，如 Chrome 和 Safari */
            ::-webkit-scrollbar {
                width: 7px;
                /* 宽度 */
            }

            ::-webkit-scrollbar-track {
                background: rgba(0, 0, 0, 0.13);
                /* 轨道颜色 */
            }

            ::-webkit-scrollbar-thumb {
                background: rgba(0, 0, 0, 0.13);
                /* 滑块颜色 */
            }

            ::-webkit-scrollbar-thumb:hover {
                background: rgba(0, 0, 0, 0.13);
                /* 鼠标悬停时的滑块颜色 */
            }
        }

        .arco-modal-footer {
            padding: 12px 32px 24px;
            border-top: none;
            .flex(8);
            flex-direction: row-reverse;

            .arco-btn {
                margin-left: 0;
            }
        }

        .arco-modal-close-icon {
            display: block;
            width: 24px;
            height: 24px;
            right: 32px;
            top: 24px;
        }
    }
}
.arco-btn-primary:not(.arco-btn-disabled):focus-visible{
    box-shadow:none!important;
}

.swiopenDialog .arco-modal-content {
    // height: 428px !important;
    max-height: 428px !important;
}
.specialHeightForApply .arco-modal-content {
    height:400px!important;
}