.redqueenRadio.arco-radio-group {
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 16px;

    .arco-radio-disabled {
        &.arco-radio-checked .arco-radio-mask {
            background-color: var(--Color-Bg-Selected-Disabled);
            border-color: var(--Color-Bg-Selected-Disabled);
        }

        &.arco-radio-checked:hover .arco-radio-mask {
            border-color: var(--Color-Bg-Selected-Disabled);
        }

        .arco-radio-text {
            color: var(--Color-Text-Disabled, #AFB7C2);
        }
    }

    .arco-radio-checked .arco-radio-mask {
        background-color: var(--Color-Bg-Selected-Default);
        border-color: var(--Color-Bg-Selected-Default);
    }

    .arco-radio-checked:hover .arco-radio-mask {
        border-color: var(--Color-Bg-Selected-Default);
    }

    .arco-radio-text {
        color: var(--Color-Text-Neutral-Default)
    }

    .arco-radio {
        line-height: 22px;
        margin-right: 0;
        padding-left: 0;
    }
}