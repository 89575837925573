.redqueenCheckbox{
    padding-left:0!important;
    .arco-checkbox-checked .arco-checkbox-mask, &.arco-checkbox-checked .arco-checkbox-mask{
        background-color:var(--Color-Border-Selecated-Default);
    }
    .arco-checkbox-mask{
        border:1px solid var(--Color-Border-Input-Default)
    }
    .arco-checkbox-text{
        color:var(--Color-Text-Neutral-Default)
    }
    &.arco-checkbox-group {
        display:flex;
        gap:16px;
        flex-wrap:wrap;
        align-items: center;
        .arco-checkbox{
            padding-left:0;
            margin-right:0;
            line-height:22px;
        }
    }
    .arco-icon-hover.arco-checkbox-icon-hover::before{
        width:20px;
        height:20px;
        border-radius:3px;
    }
}
.aaa{
    color:red;
}

.cardCollectionEnButton{
        position: absolute !important;
        top: 8px;
        right: 8px;
}