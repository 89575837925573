@import (reference) '@/style/public';
.emptyBody{
    .flexc(0);
    justify-content: center;
    align-items: center;
    .mainContent{
        .flexc(20);
        align-items: center;
        p{
            color: var(--Color-Text-Subtle);
            .height(22);
            text-align:center;
            margin:0;
            padding:0;
        }
    }
}