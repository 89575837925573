@import (reference) '@/style/public';

.redqueenMessage {
    &.arco-message {
        padding: 9px 10px;
        height: 22px;
        border: 1px solid var(--Color-Border-Accent-Neutral-Bolder);
        box-shadow: 8px 8px 30px 0px rgba(39, 70, 120, 0.15);
        line-height: 22px;

        .arco-message-icon {
            height: 20px;
        }

        .arco-message-content {
            color: var(--Color-Text-Neutral-Default);
        }
    }
    position:relative;
    top:67px;
}

.redqueenAlert {
    width:389px;
    border-radius: 2px !important;
    padding: 16px !important;
    box-shadow: 6px 6px 30px 0px rgba(2, 47, 105, 0.07) !important;
    
    position:relative;
    top:67px;
    svg {
        width: 20px;
        height: 20px;
    }
}

.redqueenAlert.default {
    border: 1px solid var(--Color-Border-Accent-Blue-Bolder, #106CBD);
    background: var(--Color-Bg-Accent-Blue-Subtler, #E8F7FF);

    svg {
        path {
            fill: #106CBD
        }

        circle {
            stroke: #106CBD
        }
    }

}

.redqueenAlert.success {
    border: 1px solid var(--Color-Border-Accent-Green-Bolder, #1F845A);
    background: var(--Color-Bg-Accent-Green-Sublest, #F3FFF9);

    svg {
        path {
            fill: #1F845A
        }

        circle {
            stroke: #1F845A
        }
    }
}

.redqueenAlert.warning {
    border: 1px solid var(--Color-Border-Accent-Yellow-Bolder, #876B02);
    background: var(--Color-Bg-Warning-Subtlest-Default, #FFF9E3);

    svg {
        path {
            fill: #E2B203
        }

        circle {
            stroke: #E2B203
        }
    }

}

.redqueenAlert.error {
    border: 1px solid var(--Color-Border-Accent-Red-Bolder, #AD0C24);
    background: var(--Color-Bg-Accent-Red-Subtlest, #FFF4F2);

    svg {
        path {
            fill: #AD0C24
        }

        circle {
            stroke: #AD0C24
        }
    }

}