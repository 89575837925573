@import (reference) '@/style/public';
.userInfoContainer {
    border: 2px solid var(--Color-Border-Accent-Neutral-Subtlest);
    background: var(--surface-gradient-rasied-default, linear-gradient(180deg, #F3F5F8 0%, #FFF 100%));
    box-shadow: 8px 8px 30px 0px rgba(39, 70, 120, 0.15);
    width: 276px;
    padding: 24px 16px;
    margin-top:-4px;
    .flexc(16);
    .topList{
        .flexc(4);
        padding:0 8px;
        .userInfo{
            padding:8px 0 20px;
            position:relative;
            .userType{
                width:58px;
                height:16px;
                text-align:center;
                line-height:16px;
                position:absolute;
                left:-5px;
                bottom:20px;
                // font-size:12px;
                // color:white;
            }
            .flex(12);
            .info{
                line-height:22px;
                width:calc(100% - 60px);
                .top{
                    color: var(--Color-Text-Neutral-Default);
                    font-size:18px;
                    font-weight:500;
                }
                .bottom{
                    span{
                        color: var(--Color-Text-Subtler, #828FA1);
                    }
                }
            }
            img{
                width:48px;
                height:48px;
            }
            &:after{
                content:"";
                width:100%;
                height:1px;
                background-color: var(--Color-Border-Accent-Neutral-Bolder);
                left:0;
                bottom:4px;
                position:absolute;
            }
        }
        .servicePart{
            padding:8px 0;
            .flex(0);
            justify-content: space-between;
            .left{
                .top,.bottom{
                    .height(22);
                }
                .top{
                    color: var(--Color-Text-Subtler);
                }
                .bottom{
                    color: var(--Color-Text-Neutral-Default)
                }
            }
            .right{
                .flexc(0);
                height:44px;
                justify-content: flex-end;
            }
        }
        .sourceCount{
            padding:8px 0;
            .flexc(4);
            .count{
                // color: var(--Color-Text-Neutral-Default);
                color:var(--Color-Text-Subtler);
                font-weight:500;
                .height(22); 
            }
            .detail{
                li{
                    display:block;
                    // .height(22);
                    line-height:22px;
                    color: var(--Color-Text-Neutral-Default);
                    .flex(0);
                    align-items: flex-start;
                    .contralWidth{
                        width: calc(100% - 71px);
                        word-break: break-all;
                        font-size:12px;
                    }
                    // .overflow(1);
                }
                small{
                    font-size:16px;
                    font-weight:500;
                }
            }
        }
        .subscription{
            padding:8px 0;
            // color: var(--Color-Text-Neutral-Default);
                color:var(--Color-Text-Subtler);
            font-weight:500;
            .height(22);
        }
    }
}
.addHoverStyle{
    &:hover{
        color:var(--Color-Text-Brand-Hovered)!important
    }
}