@import (reference) '@/style/public';
@height: 60px;

.headerBody {
    padding: 0 32px;
    height: @height;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 102;
    box-sizing: border-box;
    background-color: var(--Elevation-Surface-Sunken-Subtler);
    .flex(0);
    justify-content: space-between;

    // width:100%;
    &.addBoxShadow {
        // box-shadow: 6px 6px 30px 0px rgba(2, 47, 105, 0.07);
        box-shadow: 8px 8px 30px 0px rgba(39, 70, 120, 0.15);
    }

    transition: box-shadow .3s;

    .leftMenuList {
        height: @height;
        float: left;
        display: flex;
        justify-content: space-between;
        gap: 32px;
        align-items: center;

        .menuList {
            .flex(12);
            position:relative;
            &:after{
                content:"";
                width:100%;
                height:2px;
                position:absolute;
                left:0;bottom:-22px
            }

            >a {
                // display: block;
                // height: @height;
                // line-height: @height;
                margin: 0 4px;
                position: relative;
                font-weight: 400;
                transition: color .3s;
                color: var(--Color-Text-Neutral-Default);
                font-size: 14px;

                span {
                    position: relative;

                    &:after {
                        content: "";
                        width: 0;
                        position: absolute;
                        left: 50%;
                        bottom: -22px;
                        height: 2px;
                        background-color: var(--Color-Icon-Accent-Red-Subtle) !important;
                        border-radius: 1px 1px 0px 0px;
                        transition: all .3s;
                        opacity: 0
                    }
                }

                // &.active{
                //     color:var(--Color-Text-Brand-Default);
                //     font-weight:500;
                // &:after{
                //     content:"";
                //     width:100%;
                //     height:2px;
                //     background-color:var(--Color-Icon-Accent-Red-Subtle);
                //     border-radius: 1px 1px 0px 0px;
                //     position:absolute;
                //     left:10px;
                //     bottom:0;
                //     border-radius: 1px 1px 0px 0px;
                // }
                // }
                &:hover {
                    color: var(--Color-Text-Brand-Default)
                }
            }
            
        }
        .apiLogo{
            position: relative;
            left: -12px;
        }
        .apiUseCenter{
            color: var(--Color-Text-Neutral-Default, #222B40);
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
            position: relative;
            left: -36px;
        }

    }

    .rightUserInfo {
        float: right;
        height: @height;
        .flex(24);

        li {
            height: 100%;
            display: flex;
            align-items: center;
            gap: 8px;

            span {
                color: var(--Color-Text-Neutral-Default);
                // cursor:pointer;
                // &:hover{
                //     color:var(--Color-Text-Brand-Default)
                // }
            }

            img {
                width: 32px;
                height: 32px;
            }
        }
        .downFile{
            position: relative;
            right: -20px
        }
        .downSdkFile{
            position: relative;
            right: -4px
        }
    }
}

.changeLanguage {
    .height(22);
    .flex(4);
    color: #000;

    &.spcetalLanguageStyle {
        color: #fff
    }
}

.searchInputPosition {
    position: absolute !important;
    right: 0;
    top: 12px;
    margin-bottom: 0 !important;
    transition: width .3s;
}

// .messageCount{
//     width:20px;
//     .height(20);
//     background:var(--Color-Bg-Accent-Red-Bolder, #AD0C24);
//     border-radius:50%;
//     font-size:12px;
//     color:var(--Color-Text-Inverse, #FFF);
// }
.specialNotice {
    path {
        fill: #fff
    }
}

:global {
    .redqueenMessageDialog.redqueenModalContainer.arco-modal {
        padding-top: 0 !important;

        .arco-modal-content {
            padding: 0;
            margin: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            max-height: 100%;
        }
    }

    .specialHeader {
        background: transparent !important;

        .redqueenDropdown.arco-btn {
            color: var(--Color-Text-Inverse, #FFF) !important;
            opacity: 0.6;

            svg {
                path {
                    fill: var(--Color-Text-Inverse, #FFF) !important;
                    opacity: 0.6;
                }
            }
        }


        .active .redqueenDropdown.arco-btn {
            opacity: 1;

            span:after {
                background-color: var(--Color-Text-Inverse, #FFF) !important;
            }
        }

        .active svg {
            path {
                fill: var(--Color-Text-Inverse, #FFF) !important;
                opacity: 1;
            }
        }
    }
}