@import (reference) '@/style/public';
.redqueenDropdown.arco-btn {
    height: 60px;
    color: var(--Color-Text-Neutral-Default) !important;
    background-color: unset !important;
    padding: 0;
    font-weight: 400;
    font-size: 14px;
    border: none !important;
    transition: color .3s;
    &:focus-visible{
        box-shadow:none;
    }
    &:hover {
        color: var(--Color-Text-Brand-Default) !important;
    }
    &.menuListDropDown{
        height:20px;
        .flex(8);
        span{
            line-height:20px;
        }
        svg{
            margin-left:0;
        }
    }
}

.active {
    // color: var(--Color-Text-Brand-Default) !important;

    position: relative;

    .redqueenDropdown.arco-btn {
        color: var(--Color-Text-Brand-Default) !important;
        font-weight: 500;
    }
    span {
        &:after {
            // content: "";
            width: 100%!important;
            // height: 2px;
            // background-color: var(--Color-Icon-Accent-Red-Subtle) !important;
            // border-radius: 1px 1px 0px 0px;
            // position: absolute;
            left: 0!important;
            opacity:1!important;
            // bottom: 0;
            // border-radius: 1px 1px 0px 0px;
        }
    }
}
.arco-btn .arco-icon-down{
    transition: transform .15s;
}
.arco-dropdown-popup-visible .arco-icon-down {
    transform: rotate(180deg);
}

.arco-dropdown-menu {
    padding: 4px 0px;
    border-radius: 4px;
    border: 1px solid var(--Color-Border-Accent-Neutral-Subtle);
    background: var(--Color-Bg-Neutral-Subtlest-Default);

    /* Light/Elevation.Shadow.Ltitle */
    box-shadow: 0px 2px 4px 0px rgba(10, 70, 89, 0.05);
}
.arco-dropdown-menu-item, .arco-dropdown-menu-pop-header{
    padding: 0!important;
    a{
        display:block;
        padding:7px 12px;
        height:22px;
        line-height:22px;
        color:var(--Color-Text-Neutral-Default);
    }
}
// .setMenuDropDownPosition{
//     top:-18px;
// }
.topMenuNewIcon{
    // width:18px;
    display:block;
    position:absolute;
    right:-23px;
    top:-7px;
    animation: newTransform 1s ease-out infinite;
}
@keyframes newTransform {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.2);
    }
    100%{
        transform: scale(1);
    }
}