@import (reference) '@/style/public';
.alertContainer{
    .flexc(4);
    .header{
        .flex(8);
        // .height(24);
        align-items: flex-start;
        min-height:24px;
        &>svg{
            margin-top:2px;
        }
        .right{
            width:calc(100% - 28px);
            .flex(4);
            align-items: flex-start;
            &>svg{
                margin-top:4px;
            }
            .title, .content{
                width: calc(100% - 20px);
                color: var(--Color-Text-Neutral-Default, #222B40);
                font-size: 14px;
                text-align: left;
                padding-left:0;
                line-height:22px;
                padding-top:1px;
                padding-bottom:1px;
            }
                path{
                    fill:#222B40;
                }
        }
    }
    .title{
        font-weight:500;
    }
    .content{
        font-weight:400;
        text-align: left;
        padding-left:28px;
        line-height:22px;
        padding-top:1px;
        padding-bottom:1px;
    }
    .bottomFooter{
        padding-top:6px;
        display:flex;
        flex-direction: row-reverse;
    }
}