@import (reference) '@/style/public';

.homeInputSearchContainer {
    width: 710px;
    height:52px;
    position:relative;
    z-index:20;
    margin-bottom:32px;
    .selectedTypeStyle{
        background:var(--Color-Bg-Neutral-Subtler-Default, #F5F7FA)!important;
        color:var(--Color-Text-Subtle, #515D73)!important;
        svg{
            color:#222B40;
        }
    }
    // &.specialHeader{
    //     &.topPosition{
    //         .searchInputContainer{
    //             border: 1px solid #212326;
    //             background: rgba(61, 61, 61, 0.52);
    //             box-shadow: 0px 2px 4px 0px rgba(10, 70, 89, 0.05);
    //             path{
    //                 fill:#fff;
    //             }
    //             input{
    //                 background-color:transparent!important;
    //             }
    //             .searchParamsList{
    //                 background:var(--Color-Bg-Input-Subtler, #FFF);
    //                 position:absolute;
    //                 left:0;
    //                 top:36px;
    //             }
    //         }
    //     }
    // }
    &.topPosition{
        width:380px;
        height:36px;
        .searchInputContainer{
        border: 1px solid var(--Color-Border-Input-Default, #E0E4E9);
        background: var(--Color-Bg-Input-Subtler, #FFF);
        box-shadow: 0px 2px 4px 0px rgba(10, 70, 89, 0.05);
        transition:all .2s;
        &:hover{
            border: 1px solid var(--Color-Border-Selecated-Hovered, #BD2E3C);
        }
            .topInputPart{
                .inputContainer{

                    input, .input{
                        height:22px!important;
                    }
                }
            }
        }
        .topInputPart{
            padding:6px 12px!important;
        }
    }
    .searchInputContainer{
        box-sizing:border-box;
        position:absolute;
        width:100%;
        height:auto;
        overflow:hidden;
        transition:height .2s;
        left:0;
        top:0;
        border-radius: 2px;
        background: var(--Color-Bg-Input-Subtler, #FFF);
        box-shadow: 8px 8px 30px 0px rgba(39, 70, 120, 0.15);
        border: 1px solid var(--Color-Border-Selecated-Hovered, #BD2E3C);
        .topInputPart{
            .flex(12);
            padding:8px;
            padding-left:20px;
            .leftInput{
                width:calc(100% - 88px);
                flex-grow: 1;
                .flex(8);
            }
            .inputContainer{
                flex:1 1 auto;
            }
            .input{
                width:100%;
                border:none;
                box-shadow:none;
                padding:0;
                height:36px;
                background:none;
                input{
                    width:100%!important;
                    border:none!important;
                    box-shadow:none!important;
                    padding:0!important;
                    height:36px!important;
                }
            }
        }
        .searchParamsList{
            // height:100px;
            width:708px;
            box-sizing:border-box;
            border-top: 1px solid var(--Color-Border-Accent-Neutral-Bolder, #E0E4E9);
            padding:24px;
            .flexc(24);
            li{
                .flexc(4);
                .header{
                    color: var(--Color-Text-Subtle, #58657C);
                    font-weight: 500;
                    height:22px;
                    .flex(8);
                    width:100%;
                }
                .content{
                    .flex(4);
                    width:100%;
                    &.searchHistory{
                        .flex(20);
                        row-gap: 4px;
                    }
                    .searchTemp{
                        font-size:12px;
                        padding:0 7px;
                        cursor:pointer;
                        .flex(4);
                        background-color:var(--Color-Bg-Neutral-Subtler-Default, #F5F7FA)!important;
                        color: var(--Color-Text-Subtle, #515D73)!important;
                        svg{
                            vertical-align:middle;
                            path{
                                fill:var(--Color-Text-Subtle, #515D73)
                            }
                        }
                        &:hover{
                            color: var(--Color-Link-Red-Hovered, #BD2E3C)!important;
                            path{
                                fill:var(--Color-Link-Red-Hovered, #BD2E3C);
                            }
                        }
                    }
                    .searchHistoryTemp{
                        cursor:pointer;
                        max-width:143px;
                        .height(22);
                        color: var(--Color-Text-Neutral-Default, #222B40);
                        &:hover{
                            color:var(--Color-Link-Red-Hovered, #BD2E3C);
                        }
                    }
                    &.topicalEvent{
                        .flex(24);
                        row-gap:8px;
                        .topicalTemp{
                            cursor:pointer;
                            .flex(12);
                            width:253px;
                            height:26px;
                            box-sizing:border-box;
                            padding:1px 6px 1px 0;
                            border-radius: 2px;
                            border: 2px solid var(--Color-Border-Accent-Neutral-Subtlest, #FFF);
                            background: var(--surface-gradient-rasied-default, linear-gradient(180deg, #F3F5F8 0%, #FFF 100%));
                            box-shadow: 6px 6px 30px 0px rgba(2, 47, 105, 0.07);
                            &:before{
                                content: "";
                                width:2px;
                                height:12px;
                                border-radius: 0.5px;
                                background:transparent;
                            }
                            color: var(--Color-Text-Neutral-Default, #222B40);
                            &:hover{
                                color:var(--Color-Link-Red-Hovered, #BD2E3C);
                            }
                            &.hight{
                                &:before{
                                    background: var(--Color-Bg-Accent-Red-Bolder, #AD0C24);
                                }
                            }
                            &.blue{
                                &:before{
                                    background:  var(--Color-Bg-Accent-Blue-Bolder, #106CBD);
                                }
                            }
                            .hotTitle{
                                width:calc(100% - 96px);
                            }
                            .count{
                                width:70px;
                                color: var(--Color-Text-Subtler, #828FA1);
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 20px; 
                            }
                        }
                    }
                }
            }
        }
    }
}