#tianai-captcha-parent {
    box-shadow: 0 0 11px 0 #999;
    width: 313px;
    height: 284px;
    overflow: hidden;
    position: relative;
    z-index: 997;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px
}

#tianai-captcha-parent #tianai-captcha-box {
    height: 264px;
    width: 100%;
    position: relative;
    overflow: hidden
}

#tianai-captcha-parent #tianai-captcha-box .loading {
    width: 50px;
    height: 50px;
    text-align: center;
    display: block;
    z-index: 998;
    position: absolute;
    top: 105px;
    left: 126px;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    background-image: url(../images/loading.gif);
    background-size: cover
}

#tianai-captcha-parent #tianai-captcha-box #tianai-captcha {
    transform-style: preserve-3d;
    will-change: transform;
    transition-duration: .45s;
    transform: translateX(-300px)
}

#tianai-captcha-parent #tianai-captcha-bg-img {
    background-color: #fff;
    background-position: top;
    background-size: cover;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 6px
}

#tianai-captcha-parent .slider-bottom {
    height: 19px;
    width: 100%;
    position: absolute;right: 19px;bottom: 67px;z-index: 1000;width: 24px;height: 24px;border-radius: 16px;
    background: rgba(239, 240, 241, 0.80);
}

#tianai-captcha-parent .slider-bottom .close-btn {
    width: 16px;
    height: 16px;
    background-image: url(../images/close.png);
    background-repeat: no-repeat;
    cursor: pointer;
    position:absolute;
    right:-9px;
    top:-184px;
}

#tianai-captcha-parent .slider-bottom .refresh-btn {
    width: 16px;
    height: 16px;
    background-image: url(../images/refresh.png);
    /* background-position: 0 -167px; */
    background-repeat: no-repeat;
    float: right;
    cursor: pointer;
    margin-right:4px;
    margin-top:4px;
}

#tianai-captcha-parent .slider-bottom .logo {
    height: 30px;
    float: left
}

#tianai-captcha-parent .slider-move-shadow {
    animation: myanimation 2s infinite;
    height: 100%;
    width: 5px;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    filter: opacity(0.5);
    box-shadow: 1px 1px 1px #fff;
    border-radius: 50%
}

#tianai-captcha-parent #tianai-captcha-slider-move-track-mask {
    border-width: 0px;
    border-style: solid;
    border-color: #00f4ab;
    width: 0;
    height: 40px;
    background-color: #a9ffe5;
    opacity: .5;
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: 24px
}

#tianai-captcha {
    text-align: left;
    box-sizing: content-box;
    width: 293px;
    height: 260px;
    z-index: 999
}

#tianai-captcha .slider-bottom .logo {
    height: 30px
}

#tianai-captcha .slider-bottom {
    height: 19px;
    width: 100%
}

#tianai-captcha .content .tianai-captcha-tips {
    height: 25px;
    width: 100%;
    position: absolute;
    bottom: -25px;
    left: 0;
    z-index: 999;
    font-size: 15px;
    line-height: 25px;
    color: #fff;
    text-align: center;
    transition: bottom .3s ease-in-out
}

#tianai-captcha .content .tianai-captcha-tips.tianai-captcha-tips-error {
    background-color: #ff5d39
}

#tianai-captcha .content .tianai-captcha-tips.tianai-captcha-tips-success {
    background-color: #39c522
}

#tianai-captcha .content .tianai-captcha-tips.tianai-captcha-tips-on {
    bottom: 0
}

#tianai-captcha .content #tianai-captcha-loading {
    z-index: 9999;
    background-color: #f5f5f5;
    text-align: center;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center
}

#tianai-captcha .content #tianai-captcha-loading img {
    display: block;
    width: 45px;
    height: 45px
}

#tianai-captcha #tianai-captcha-slider-bg-canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px
}

@keyframes myanimation {
    from {
        left: 0
    }

    to {
        left: 289px
    }
}

#tianai-captcha.tianai-captcha-slider {
    z-index: 999;
    position: absolute;
    left: 0;
    top: 0;
    user-select: none
}

#tianai-captcha.tianai-captcha-slider .content {
    width: 100%;
    height: 182px;
    position: relative;
    overflow: hidden
}

#tianai-captcha.tianai-captcha-slider .bg-img-div {
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translate(0px, 0px)
}

#tianai-captcha.tianai-captcha-slider .bg-img-div img {
    height: 100%;
    width: 100%;
    border-radius: 5px
}

#tianai-captcha.tianai-captcha-slider .slider-img-div {
    height: 100%;
    position: absolute;
    transform: translate(0px, 0px)
}

#tianai-captcha.tianai-captcha-slider .slider-img-div #tianai-captcha-slider-move-img {
    height: 100%
}

#tianai-captcha.tianai-captcha-slider .slider-move {
    height: 40px;
    width: 100%;
    margin: 10px 0;
    position: relative
}

#tianai-captcha.tianai-captcha-slider .slider-move-track {
    position: relative;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #999;
    transition: 0s;
    font-size: 14px;
    box-sizing: content-box;
    /* border: 1px solid #f5f5f5; */
    border-radius: 24px;
background: rgba(97, 104, 120, 0.05);
}

#tianai-captcha.tianai-captcha-slider .refresh-btn,
#tianai-captcha.tianai-captcha-slider .close-btn {
    display: inline-block
}

#tianai-captcha.tianai-captcha-slider .slider-move {
    line-height: 38px;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    color: #88949d;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    filter: opacity(0.8)
}

#tianai-captcha.tianai-captcha-slider .slider-move .slider-move-btn {
    transform: translate(0px, 0px);
    position: absolute;
    background-repeat: no-repeat;
    border-radius: 24px;
     height: 36px;
    left: -2px;
    position: absolute;
    top: 2px;
    transform: translate(0);
    width: 53px;
    
}

#tianai-captcha.tianai-captcha-slider .slider-tip {
    margin-bottom: 10px;
    height: 22px;
    line-height: 22px;
    font-size: 14px;
    color: #000;
    font-weight:400;
}

#tianai-captcha.tianai-captcha-slider .slider-move-btn:hover {
    cursor: pointer
}

#tianai-captcha.tianai-captcha-rotate .rotate-img-div {
    height: 100%;
    text-align: center
}

#tianai-captcha.tianai-captcha-rotate .rotate-img-div img {
    height: 100%;
    transform: rotate(0deg);
    display: inline-block
}

#tianai-captcha.tianai-captcha-concat .tianai-captcha-slider-concat-img-div {
    background-size: 100% 180px;
    position: absolute;
    transform: translate(0px, 0px);
    z-index: 1;
    width: 100%
}

#tianai-captcha.tianai-captcha-concat .tianai-captcha-slider-concat-bg-img {
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translate(0px, 0px);
    background-size: 100% 180px
}

#tianai-captcha.tianai-captcha-word-click {
    position: relative;
    box-sizing: border-box
}

#tianai-captcha.tianai-captcha-word-click .click-tip {
    position: relative;
    height: 40px;
    width: 100%
}

#tianai-captcha.tianai-captcha-word-click .click-tip .tip-img {
    width: 130px;
    position: absolute;
    right: 15px
}

#tianai-captcha.tianai-captcha-word-click .click-tip #tianai-captcha-click-track-font {
    font-size: 18px;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    position: absolute
}

#tianai-captcha.tianai-captcha-word-click .slider-bottom {
    position: relative;
    top: 6px
}

#tianai-captcha.tianai-captcha-word-click .content #bg-img-click-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0
}

#tianai-captcha.tianai-captcha-word-click .content #bg-img-click-mask .click-span {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50px;
    background-color: #409eff;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    color: #fff;
    border: 2px solid #fff;
    box-sizing: content-box
}