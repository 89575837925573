.footerBody {
    background-color: var(--Neutral-09);
    position: relative;
    z-index: 100;

    .middleContainer {
        width: 1180px;
        padding: 24px 0;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 1320px;
    }

    .topList {
        padding-bottom: 12px;
        display: flex;
        flex-direction: column;
        gap: 14px;
        border-bottom: 1px solid var(--Neutral-07);

        .infoList {
            display: flex;
            align-items: center;
            gap: 106px;

            .list {
                flex: 0 0 auto;
                display: flex;
                gap: 16px;
                flex-direction: column;
                // padding-bottom: 27px;

                .title {
                    height: 22px;
                    line-height: 22px;
                    color: var(--Color-Icon-Inverse);
                    font-weight: 700;
                    font-size: 12px;
                }

                .content {
                    display: flex;
                    gap: 50px;
                    flex-wrap: wrap;

                    .columnTemplate {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;

                        div {
                            display: flex;
                            gap: 8px;
                            align-items: center;
                            padding: 1px 0;
                            line-height: 20px;

                            &.lineStyle {
                                cursor: pointer;

                                &:hover {

                                    small,
                                    span {
                                        color: var(--Color-Link-Red-Default, #AD0C24);
                                    }
                                }
                            }

                            span,
                            small {
                                color: var(--Color-Text-Inverse);
                                font-weight: 300;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }

            .companyCode {
                flex: 1 0 auto;

                .div {
                    color: var(--Color-Icon-Inverse);
                    font-weight: 700;
                    line-height: 22px;
                    text-align: right;
                    ;
                    width: 100%;
                    float: right;
                }
            }
        }
    }

    .bottomInfo {
        display: flex;
        gap: 4px;
        flex-direction: column;

        .rowTemp {
            text-align: center;
            height: 18px;
            line-height: 18px;
            display: flex;
            gap: 16px;
            justify-content: center;

            span {
                font-size: 12px;
                color: var(--Color-Text-Inverse);
                font-weight: 300;
                // margin:0 6px;
            }
        }
    }
}