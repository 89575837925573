.redqueenBadge {
    &.arco-badge-number{
        height: 14px;
        padding: 0 5px;
        min-width: 6px;
        box-sizing:content-box;
        line-height:14px;
        border: 1px solid var(--Color-Border-Accent-Neutral-Subtlest, #FFF)!important;
        background-color: var(--Color-Bg-Accent-Red-Bolder, #AD0C24)!important;
        left:50%;
        right:auto;
        // margin-right: -6px;
        top:0;
        display:block;
        transform:translate(-1px, -8px);
    
        span {
            color: var(--Color-Text-Inverse, #FFF) !important;
            font-size: 10px;
            display:block;
        }
    }
    &.arco-badge-dot{
        background: var(--Color-Icon-Accent-Red-Subtle, #AD0C24);
    }
}