@import (reference) '@/style/public';
.redqueenFormItem{
    &.arco-form-item{
        margin-bottom:0;
    }//整行样式
    &.arco-form-layout-inline{
        .flex(12);
        margin-right:0;
        > .arco-form-label-item{
            padding-right:0;
            text-align: right;
            label{
                .flex(8);
                justify-content: flex-end;
            }
            .arco-form-item-symbol{
                svg{
                    transform-origin: left;
                }
                color:var(--Color-Icon-Accent-Red-Subtle, #AD0C24);
                width:7px;
                height:auto;
                display:block;
            }
        }
        .arco-form-item-wrapper{
            flex:1 2 auto;
            width:auto;
        }
    }
}
    