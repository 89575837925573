@import (reference) '@/style/public';
.subDialogContainer{
    padding: 0 24px;
    .header{
        color: var(--Color-Text-Neutral-Default, #222B40);
        margin-bottom:12px;
        span{
            color: var(--Color-Text-Accent-Red, #AD0C24);
        }
    }
    label{
        float:left;
    }
}
.showDialogHerader {
    .flex(8);
    color: var(--Color-Text-Neutral-Default, #222B40);
    font-size: 20px;
    font-weight: 500;

    &:before {
        content: "";
        width: 3px;
        height: 12px;
        background-color: var(--Color-Icon-Accent-Red-Subtle);
    }

    // span {
    //     display: block;
    //     padding: 0 6px;
    //     .height(28);
    //     color: var(--Color-Text-Accent-Blue) !important;
    //     background: var(--Color-Bg-Accent-Blue-Subtlest, #E8F7FF);
    //     border-radius: 2px;
    //     font-size: 14px !important;
    // }
}