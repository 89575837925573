.arco-table-pagination{
    .paginationCustomization{
        .mixin
    }
}
.paginationCustomization{
    .mixin
}
.mixin() {
    color: red;
    .arco-pagination-total-text{
        color:var(--Color-Text-Neutral-Default)
    }
    .arco-pagination-jumper-text-goto-suffix {
        display: none;
    }

    .arco-pagination-item {
        border: 1px solid var(--Color-Border-Input-Default);
        background-color: var(--Color-Bg-Input-Subtle);
        padding: 4px;
        width: auto;
        min-width: 32px;
        line-height: 22px;
        // transition:all .3s;
        border-radius: 2px;
    }

    .arco-pagination-item:not(.arco-pagination-item-disabled):not(.arco-pagination-item-active):hover {

        background-color: var(--Color-Bg-Neutral-Subtler-Hovered);
        // background-color:red;
        border: 1px solid var(--Color-Border-Input-Default);
    }

    .arco-pagination-item-active {
        background-color: var(--Color-Bg-Selected-Default);
        border: none;
        // height:24px;
        line-height: 24px;
        color: var(--Color-Text-Inverse);
        font-weight: 600;
    }

    &.arco-pagination-size-small {
        .arco-pagination-item {
            line-height: 18px;
            min-width: 28px;
        }

    }

    &.arco-pagination-size-large {
        .arco-pagination-item {
            min-width:36px;
            line-height: 26px;
        }
    }
        &.arco-pagination-size-mini {
        .arco-pagination-item {
            line-height: 14px;
            font-size: 12px;
            margin-right: 4px;
            min-width:24px;
        }
        .arco-pagination-option{
            margin-left:0;
        }
        .arco-pagination-jumper{
            margin-left:4px;
        }
    }
    .arco-select .arco-select-view{
        border: 1px solid var(--Color-Border-Input-Default);
        background-color: var(--Color-Bg-Input-Subtle);
    }
    .arco-select:hover .arco-select-view{
        background-color: var(--Color-Bg-Neutral-Subtler-Hovered);
        border: 1px solid var(--Color-Border-Input-Default);
    }
    .arco-select-single .arco-select-view{
        padding:0 12px!important;
    }
    .arco-select-single .arco-select-view-value, .arco-select-single .arco-select-view-value-mirror{
        color:var(--Color-Icon-Neutral-Default)
    }
    .arco-select .arco-select-arrow-icon{
        color:var(--Color-Icon-Neutral-Default)
    }
    .arco-pagination-jumper > span{
        color:var(--Color-Text-Subtler)
    }
    &.arco-pagination .arco-pagination-jumper-input{
        border: 1px solid var(--Color-Border-Input-Default);
        background-color: var(--Color-Bg-Input-Subtle);
        margin-right:0;
        color:var(--Color-Text-Neutral-Default)
    }
    .arco-input:hover{
        background-color: var(--Color-Bg-Neutral-Subtler-Hovered);
        border: 1px solid var(--Color-Border-Input-Default);
    }
    &.arco-pagination {
        justify-content: flex-end;
        .arco-pagination-item-prev.arco-pagination-item-disabled,.arco-pagination-item-next.arco-pagination-item-disabled{
            background-color:var(--Color-Bg-Input-Subtle);
            color:var(--Color-Text-Disabled)
        }
    }
    .arco-icon{
        font-size:12px;
    }
}

#webpack-dev-server-client-overlay {
    display: none !important;
}
.arco-select-popup .arco-select-popup-inner{
    max-height: 250px!important;
}