@import (reference) '@/style/public';
div.redqueenTag{
    font-weight:400;
    .flex(4);
    display:inline-flex;
    // max-width:74px;
    &.arco-tag-size-small{
        padding:0 4px;
        font-size:12px;
    }
    &.arco-tag-size-default{
        padding:0 6px;
        font-size:12px;
        display: inline-block;
        margin-right: 4px;
    }
    &.arco-tag-size-medium{
        padding:0 6px;
        font-weight: 400;
    }
    span{
        color:inherit!important;
        font-size:inherit!important;
        margin-right:0;

    }
    .arco-tag-close-btn{
        width:12px;
        height:12px;
        svg{
            width:12px;
            height:12px;
        }
        &:hover{
            &:before{
                background-color:transparent!important;
            }
        }
    }
    &.styleDefault{
        color:var(--Color-Text-Neutral-Default)!important;
        background-color:var(--Color-Bg-Accent-Neutral-Subtler)!important;
        &.arco-tag-bordered{
            border-color:var(--Color-Border-Accent-Neutral-Bolder)!important;
        }
    }

    &.typeStyle{
        &.arco-tag-bordered{
            border-color:var(--Color-Border-Accent-Neutral-Subtlest, #FFF) !important
        }
        &:hover{
            background-color:transparent!important;
        }
    }
    &.ortstyle{
        background-color:var(--color-bg-accent-neutral-subtlest, #FFF) !important;
    }

    &.styleError{
        color:var(--Color-Text-Accent-Red)!important;
        background-color:var(--Color-Bg-Accent-Red-Subtlest)!important;
        &.arco-tag-bordered{
            border-color:var(--Color-Border-Accent-Red-Subtlest)!important;
        }
    }
    &.styleSuccess{
        color:var(--Color-Text-Accent-Green)!important;
        background-color:var(--Color-Bg-Accent-Green-Sublest)!important;
        &.arco-tag-bordered{
            border-color:var(--Color-Border-Accent-Green-Sublest)!important;
        }
    }
    &.styleWarning{
        color:var(--Color-Text-Accent-Yellow)!important;
        background-color:var(--Color-Bg-Accent-Yellow-Subtlest)!important;
        &.arco-tag-bordered{
            border-color:var(--Color-Border-Accent-Yellow-Sublest)!important;
        }
    }
    &.styleBlue{
        color:var(--Color-Text-Accent-Blue)!important;
        background-color:var(--Color-Bg-Accent-Blue-Subtlest)!important;
        &.arco-tag-bordered{
            border-color:var(--Color-Border-Accent-Blue-Subtlest)!important;
        }
    }
}

