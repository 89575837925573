@charset "utf-8";
@import (reference) '@/style/public';

body {
  margin: 0px;
  padding: 0px;
  background: var(--Elevation-Surface-Subtler);
  --color-primary-light-2: var(--Color-Bg-Accent-Red-Subtlest) !important;
  // width:auto!important;
  // overflow:auto!important;
}

a {
  text-decoration: none;
  color: initial;
}

/* a:hover {
    text-decoration: underline;
} */

hr {
  width: 1px;
  height: 12px;
  background: var(--Color-Border-Accent-Neutral-sBolder, #AFB7C2);
  border: none;
  margin: 0;
  padding: 0;
}

em {
  font-style: normal;
  font-size: inherit;
  font-weight: inherit;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  border: none;
  display: block;
}

*,
small {
  font-size: 14px;
  font-family: 'Source Han Sans CN', 'pingfang SC', 'helvetica neue', arial, 'hiragino sans gb', 'microsoft yahei ui', 'microsoft yahei', simsun, sans-serif;
  box-sizing: content-box;
  scrollbar-width: thin;
}

.overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.containerOutgate {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: auto;
}

.redqueenLoading {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinBakcColor .arco-spin-icon svg {
  color: var(--Color-Border-Accent-Red-Bolder);
}

.spinBakcColor .arco-spin-children::after {
  background-color: rgba(255, 255, 255, 0.30);
}

input,
textarea,
select {

  &::-webkit-input-placeholder {
    color: var(--Color-Text-Subtlest) !important;
  }

  &::-moz-placeholder {
    color: var(--Color-Text-Subtlest) !important;
  }

  &:-ms-input-placeholder {
    color: var(--Color-Text-Subtlest) !important;
  }
}

.errorStyle {
  color: var(--Color-Text-Danger, #AD0C24);
  font-size: 12px;
  line-height: 20px;
}

.arco-select {
  display: block;
}

//模糊效果
.fuzzyStyle {
  position: relative;

  * {
    filter: blur(2px);
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.tianai-captcha-back {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;

  #tianai-captcha-parent {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.modalFormLineTemp {
  .flex(4);

  .modalErrorStyle {
    border-color: var(--Color-Border-Input-Pressed) !important;

    input {
      border-color: var(--Color-Border-Input-Pressed) !important;
    }
  }

  .modalError {
    color: var(--Color-Text-Danger);
    // text-align: right;
    font-size: 12px;
    .height(20);
    // padding-left: 84px;
  }
}

//首次登录弹框
.firstOpenDialog {
  position: fixed;
  inset: 0;
  background: red;
  z-index: 999;
  background: rgba(92, 95, 99, 0.70);

  backdrop-filter: blur(0px);
  .flex();
  justify-content: center;

  .backImage {
    width: 549px;
    height: 328px;
    position: relative;
    .closeSign{
      position:absolute;
      right:-32px;
      top:-32px;
      cursor: pointer;
    }
    .closeButton{
      width:157px;
      height:40px;
      border-radius:2px;
      position:absolute;
      left:51px;
      bottom:42px;
      cursor:pointer;
    }
  }
}
.arco-input-tag-size-large .arco-input-tag-view{
  height:34px;
  overflow:hidden;
}
.arco-input-tag-size-default .arco-input-tag-view{
  height:30px;
  overflow:hidden;
}

.mocksuc.redqueenAlertModal{
  border-radius: 2px !important;
  border: 1px solid var(--Color-Border-Accent-Green-Bolder, #1F845A) !important;
  background: var(--Color-Bg-Accent-Green-Sublest, #F3FFF9) !important;
  
  /* Light/Elevation.Shadow.Raised */
  box-shadow: 6px 6px 30px 0px rgba(2, 47, 105, 0.07) !important;
  padding: 16px !important;
  .arco-modal-header{
    padding: 0 !important;
  }
  .arco-modal-content{
    background: var(--Color-Bg-Accent-Green-Sublest, #F3FFF9) !important;
  }
  .arco-modal-content{
    margin-top: 4px !important;
    padding-left: 24px !important;
  }
  .arco-modal-close-icon{
    width: 16px;
    height: 16px;
    right: 16px;
    top: 20px;
  }
  .sucIcon{
    position: relative;
    top: 4px;
    margin-right: 4px;
  }
  .arco-btn{
    border-radius: 2px !important;
    background: var(--Color-Bg-Success-Default, #1F845A) !important;
  }
}