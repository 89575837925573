@import (reference) '@/style/public';

.customPovoverStyle {
    .arco-popover-content-inner {
        color: var(--Color-Text-Inverse);
        // font-size:14px;
        line-height: 22px;
    }

    .arco-popover-content {
        padding: 8px 12px;
        background: var(--Color-Bg-Neutral-Bold-Default)
    }

    .arco-trigger-arrow {
        width: 0;
        height: 0;
        border: 4px solid transparent !important;
        border-bottom-color: var(--Color-Bg-Neutral-Bold-Default) !important;
        border-right-color: var(--Color-Bg-Neutral-Bold-Default) !important;
        background-color: transparent !important;
        bottom: -3px !important;
        margin-left: -3px !important;
        border-radius: 0 !important;
        ;
    }

    &.arco-trigger {
        margin-top: 4px;
    }
}
.popoverContainer {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size:inherit;
    color:inherit;
    font-weight:inherit;
    span{
        font-size:inherit;
        color:inherit;
        font-weight:inherit;
    }
}