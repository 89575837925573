

.overflow(@line: 0) when  (@line > 1) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: @line;
    // word-break: keep-all;
    // word-break: break-all;
    overflow-wrap: break-word;
    white-space: normal;
}
.overflow(@line:0) when (@line < 2) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.height(@height) {
    height: @height * 1px;
    line-height: @height * 1px;
}

.flex(@gap: 0) {
    display: flex;
    // flex-direction: ;
    align-items: center;
    gap: @gap * 1px;
    flex-wrap: wrap;
}

.flexc(@gap: 0) {
    display: flex;
    flex-direction: column;
    // align-items: center;
    gap: @gap * 1px;
    // flex-wrap: wrap;
}