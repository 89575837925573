.badgeBody{
    padding:0 3.5px;
    border:1px solid;
    border-radius:2px;
    font-size:11px;
    height:20px;
    // line-height:20px;
    display:flex;
    align-items: center;
    box-sizing:border-box;
    &.error{
        border-color: var(--Color-Bg-Accent-Red-Bolder, #AD0C24);
        color:var(--Color-Text-Accent-Red, #AD0C24);
    }
    &.warning{
        border-color:var(--Color-Border-Accent-Yellow-Bolder, #876B02);
        color:var(--Color-Text-Accent-Yellow, #876B02);
    }
    &.success{
        border-color:var(--Color-Bg-Accent-Green-Bolder, #1F845A);
        color:var(--Color-Text-Accent-Green, #155C3F);
    }
    &.gray{
        border-color:var(--Color-Border-Accent-Neutral-sBolder);
        color:var(--Color-Border-Accent-Neutral-sBolder);
    }
    &.blue{
        border-color:var(--Color-Border-Accent-Blue-Bolder);
        color:var(--Color-Border-Accent-Blue-Bolder);
    }
}