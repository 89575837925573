@import (reference) '@/style/public';

.redqueenInput {
    &.arco-input {
        padding: 4px 12px;
        background-color: var(--Color-Bg-Input-Subtler) !important;
        border-color: var(--Color-Border-Input-Default);
        box-shadow: 0px 2px 4px 0px rgba(10, 70, 89, 0.05);
        color: var(--Color-Text-Neutral-Default);
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;

        &:hover {
            border-color: var(--Color-Border-Input-Hovered);
            // background-color: var(--Color-Bg-Input-Subtler);
        }

        &:focus {
            border-color: var(--Color-Border-Input-Pressed);
        }

        &::-webkit-input-placeholder {
            color: var(--Color-Text-Subtlest)
        }

        &::-moz-placeholder {
            color: var(--Color-Text-Subtlest)
        }

        &:-ms-input-placeholder {
            color: var(--Color-Text-Subtlest)
        }
    }

    &.arco-input-size-large {
        padding: 6px 16px;
    }

    &.arco-input-size-small {
        padding: 3px 12px;
        font-size: 12px;
    }

    &.arco-input-size-mini {
        padding: 1px 8px;
        font-size: 12px;
    }

    &.arco-input-disabled,
    &.arco-input-disabled:hover {
        border: 1px solid var(--Color-Border-Input-Default);
        background-color: var(--Color-Bg-Disabeld) !important;
        color: var(--Color-Text-Disabled);
    }
}

.redqueenInput {
    .arco-input-inner-wrapper {
        border: none !important;
        background-color: transparent !important;
        border: none;
        padding: 0 !important;
        display: block;
        position: relative;
    }

    .arco-input-inner-wrapper:hover .arco-input {
        border-color: var(--Color-Border-Input-Hovered) !important;
    }

    .arco-input {
        padding: 4px 12px !important;
        background-color: var(--Color-Bg-Input-Subtler) !important;
        border: 1px solid var(--Color-Border-Input-Default) !important;
        box-shadow: 0px 2px 4px 0px rgba(10, 70, 89, 0.05) !important;
        color: var(--Color-Text-Neutral-Default) !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;

        &:hover {
            border-color: var(--Color-Border-Input-Hovered) !important;
            // background-color: var(--Color-Bg-Input-Subtler);
        }

        &:focus {
            border-color: var(--Color-Border-Input-Pressed) !important;
        }

        &::-webkit-input-placeholder {
            color: var(--Color-Text-Subtlest) !important
        }

        &::-moz-placeholder {
            color: var(--Color-Text-Subtlest) !important
        }

        &:-ms-input-placeholder {
            color: var(--Color-Text-Subtlest) !important
        }

        &.arco-input-size-large {
            padding: 6px 16px !important;
        }

        &.arco-input-size-small {
            padding: 3px 12px !important;
            font-size: 12px !important;
        }

        &.arco-input-size-mini {
            padding: 1px 8px !important;
            font-size: 12px !important;
        }

        &.arco-input-disabled,
        &.arco-input-disabled:hover {
            border: 1px solid var(--Color-Border-Input-Default) !important;
            background-color: var(--Color-Bg-Disabeld) !important;
            color: var(--Color-Text-Disabled) !important;
        }
    }

    .arco-input-group-suffix {
        position: absolute;
        right: 10px;
        top: 0;
    }
}

.redqueenInputSearch {
    svg{
        cursor:pointer;
    }
    path{
        fill: #515D73;
    }
    &.arco-input-search.arco-input-group-wrapper .arco-input-group-suffix{
        cursor:pointer;
    }
    .arco-input-inner-wrapper {
        padding: 4px 12px;
        border-color: var(--Color-Border-Input-Default);
        background-color: var(--Color-Bg-Input-Subtler) !important;
        box-shadow: 0px 2px 4px 0px rgba(10, 70, 89, 0.05);

        .arco-input {
            padding: 0;
            height: 22px;
        }

        &.arco-input-inner-wrapper-large {
            padding: 6px 16px;

            &:hover,
            &.arco-input-inner-wrapper-focus {
                padding: 5px 15px;
            }
        }

        &.arco-input-inner-wrapper-small {
            padding: 3px 12px;

            &:hover,
            &.arco-input-inner-wrapper-focus {
                padding: 2px 11px;
            }

            .arco-input {
                height: 20px;
                font-size: 12px;
            }
        }

        &.arco-input-inner-wrapper-mini {
            padding: 1px 8px;

            &:hover,
            &.arco-input-inner-wrapper-focus {
                padding: 0px 7px;
            }

            .arco-input {
                height: 20px;
                font-size: 12px;
            }
        }

        &:hover {
            border: 2px solid var(--Color-Border-Selecated-Hovered);
            padding: 3px 11px;
            // background-color: var(--Color-Bg-Input-Subtler);
        }

        &.arco-input-inner-wrapper-focus {
            border: 2px solid var(--Color-Border-Selecated-Pressed);
            padding: 3px 11px;
            // background-color: var(--Color-Bg-Input-Subtler);
        }

        .arco-input {
            &::-webkit-input-placeholder {
                color: var(--Color-Text-Subtlest)
            }

            &::-moz-placeholder {
                color: var(--Color-Text-Subtlest)
            }

            &:-ms-input-placeholder {
                color: var(--Color-Text-Subtlest)
            }
        }
    }

    .arco-input-group-addafter {
        span {
            font-size: inherit;
            color: inherit;
            display: flex;
            align-items: center;
            gap: 6px;
        }

        .arco-btn,
        .arco-btn:hover {
            background-color: var(--Color-Bg-Brand-Bold-Default) !important;
        }

        .arco-btn-size-default {
            padding: 0 12px;
            font-size: 14px !important;
        }

        .arco-btn-size-large {
            padding: 0 12px;
            font-size: 14px !important;
        }

        .arco-btn-size-small {
            padding: 0 8px;
            font-size: 12px !important;
        }

        .arco-btn-size-mini {
            padding: 0 8px;
            font-size: 12px !important;
        }
    }
    svg{
        color:inherit!important;
    }
}

.redqueenTextarea {
    &.arco-textarea {
        resize: none;
        border-radius: 2px;
        border: 1px solid var(--Color-Border-Input-Default, #E0E4E9);
        background: var(--Color-Bg-Input-Subtler, #FFF);
        /* Light/Elevation.Shadow.Ltitle */
        box-shadow: 0px 2px 4px 0px rgba(10, 70, 89, 0.05);
        padding: 6px 12px;

        &:hover {
            background: var(--Color-Bg-Input-Hovered, #EEF1F5);
        }

        &:focus {
            border: 1px solid var(--Color-Border-Input-Pressed, #7D041C);
            background: var(--Color-Bg-Input-Subtler, #FFF);
        }
    }
}

.redqueenTextareaMax {
    // position: relative;
    padding: 6px 12px;
    border-radius: 2px;
    border: 1px solid var(--Color-Border-Input-Default, #E0E4E9);
    background: var(--Color-Bg-Input-Subtler, #FFF);
    /* Light/Elevation.Shadow.Ltitle */
    box-shadow: 0px 2px 4px 0px rgba(10, 70, 89, 0.05);
    transition:all .2s;
    &.textareHover{
        // background: var(--Color-Bg-Input-Hovered, #EEF1F5);
       border-color:var(--Color-Border-Input-Hovered);
        // &.textFocus{
        //     background: var(--Color-Bg-Input-Subtler, #FFF);
        // }
    }
    &.textareaFocus{
        border: 1px solid var(--Color-Border-Input-Pressed, #7D041C);
        background: var(--Color-Bg-Input-Subtler, #FFF);
    }
    .redqueenTextarea {
        &.arco-textarea {
            border: none;
            background: none;
            /* Light/Elevation.Shadow.Ltitle */
            box-shadow: none;
            padding: 0;
            &:hover {
                background: none;
            }
    
            &:focus {
                border: none;
                background: none;
            }
        }
    }

    .valueCount {
        // position: absolute;
        // right: 8px;
        // bottom: 6px;
        color: var(--Color-Icon-Subtlest, #AFB7C2);
        font-size: 12px;
        .height(20);
        text-align: right;
    }
}
.errorStyle {
    border-color: var(--Color-Border-Input-Pressed) !important;
    input{
        &.arco-input {
            border-color: var(--Color-Border-Input-Pressed) !important;
        }
    }
    input {
        border-color: var(--Color-Border-Input-Pressed) !important;
    }
}
.inputErrorMsg{
    color: var(--Color-Text-Danger, #AD0C24);
    font-size: 12px;
    line-height: 20px;
}