.redqueenSelect {
    &.arco-select-size-mini.arco-select-single .arco-select-view{
        height:22px;
        line-height:20px;
    }
    &.arco-select-size-small.arco-select-single .arco-select-view{
        height:26px;
        line-height:24px;
    }
    &.arco-select-size-default.arco-select-single .arco-select-view{
        height:30px;
        line-height:28px;
    }
    &.arco-select-size-large.arco-select-single .arco-select-view{
        height:34px;
        line-height:32px;
    }
    .arco-input-tag .arco-input-tag-tag {
        border: 1px solid var(--Color-Border-Accent-Neutral-Bolder);
        background: var(--Color-Bg-Accent-Neutral-Subtler);
        color: var(--Color-Icon-Neutral-Default);

        .arco-tag-close-btn {
            color: var(--Color-Icon-Neutral-Default);
            margin-left: 2px;
            font-size: 12px;

            svg {
                font-size: 12px;
            }
        }
    }

    &.arco-select {
        overflow: hidden;
        border-radius: 2px;
        border: 1px solid var(--Color-Border-Input-Default);
        background: var(--Color-Bg-Input-Subtler);
        box-shadow: 0px 2px 4px 0px rgba(10, 70, 89, 0.05);
        transition: all .2s;

        .arco-select-view {
            // height: 34px !important;
            // line-height: 32px !important;

            .arco-input-tag-input, .arco-select-view-input {
                font-size: 14px;
            }
        }

        .arco-select-view,
        .arco-select-view:hover {
            background-color: transparent;
            border: none;
            // border-top:1px solid transparent;
        }

        &:hover {
            background: var(--Color-Bg-Input-Subtler);
            border-color: var(--Color-Border-Input-Hovered);

            .arco-select-view {
                background-color: transparent;
            }
        }

        &.arco-select-focused {
            border-color: var(--Color-Border-Input-Pressed);

            .arco-select-view {
                border-color: transparent !important;
            }
        }
    }

    &.arco-select-single .arco-select-view {
        padding: 0 12px !important;
        color: var(--Color-Icon-Neutral-Default);
    }
}

.arco-trigger label {
    &.arco-checkbox-checked{
        .arco-checkbox-mask{
            border-color: #AD0C24;
            background: #AD0C24;
        }
    }
    .arco-checkbox-mask{
        border-radius: 2px;
        border: 1px solid var(--Color-Border-Input-Default, #E0E4E9);
        background: var(--Color-Bg-Input-Subtler, #FFF);
    }
    // display: none;
}