@import (reference) '@/style/public';
.anchorContainer{
    width:100%!important;
    height:auto;
    // padding:12px 0;
    box-sizing:border-box;
    .arco-anchor-line-slider{
        width:3px;
        margin-top:6px;
        background-color:var(--Color-Text-Accent-Neutral);
        height:22px;
    }
    .arco-anchor-list{
        padding:0 22px 0 25px;
        margin-left:0;
        &:before{
            display:none;
        }
        .disable{
            cursor:not-allowed;
            padding:6px 8px;
            .height(22);
            color: var(--Color-Text-Subtlest);
        }
        .arco-anchor-link{
            padding:6px 8px;
            margin-bottom:0;
            .height(22);
            &:hover{
                background: var(--Color-Bg-Neutral-Subtler-Hovered);
            }
            .arco-anchor-link-title{
                color: var(--Color-Text-Subtle);
                padding:0;
                margin:0;
                .height(22);
                .flex(12);
                &:hover{
                    background:none;
                }
            }
            &.arco-anchor-link-active{
                .arco-anchor-link-title{
                    color: var(--Color-Text-Accent-Red);
                    font-weight:500;
                }
            }
           
        }
    }
}