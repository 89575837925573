.redqueenTable {
    .arco-table-border .arco-table-th:first-child, .arco-table-border .arco-table-td:first-child{
        border-left:1px solid var(--Color-Border-Accent-Neutral-Bolder, #E0E4E9);
    }
    .arco-table-border .arco-table-container::before{
        background-color:var(--Color-Border-Accent-Neutral-Bolder, #E0E4E9)
    }
    .arco-table-border .arco-table-container{
        
    border-right: 1px solid var(--Color-Border-Accent-Neutral-Bolder, #E0E4E9);
    border-top: 1px solid var(--Color-Border-Accent-Neutral-Bolder, #E0E4E9);
    }
    .arco-table {
        .arco-table-td {
            line-height: 22px;
            color: var(--Color-Text-Neutral-Default);
            vertical-align: text-top;
            // var(--Color-Border-Accent-Neutral-Bolder, #E0E4E9)
            // border-left:1px solid var(--Color-Border-Accent-Neutral-Bolder, #E0E4E9);
            border-bottom:1px solid var(--Color-Border-Accent-Neutral-Bolder, #E0E4E9);
        }

        .arco-table-th {
            background-color: var(--Color-Bg-Neutral-Subtler-Default);
            color: var(--Color-Text-Subtle, #515D73);
            line-height: 22px;
            border-bottom:1px solid var(--Color-Border-Accent-Neutral-Bolder, #E0E4E9);
        }

        &.arco-table-size-middle,
        &.arco-table-size-small,
        &.arco-table-size-mini {

            .arco-table-td,
            .arco-table-th .arco-table-th-item {
                line-height: 20px;
                font-size: 12px;
            }
        }

        &.arco-table-size-middle {

            .arco-table-td,
            .arco-table-th .arco-table-th-item {
                padding: 8px 16px;
            }
        }

        &.arco-table-size-small {

            .arco-table-td,
            .arco-table-th .arco-table-th-item {
                padding: 6px 16px;
            }
        }

        &.arco-table-hover .arco-table-tr:not(.arco-table-empty-row):hover .arco-table-td:not(.arco-table-col-fixed-left):not(.arco-table-col-fixed-right) {
            background-color: var(--Color-Bg-Neutral-Subtlest-Hovered)
        }
    }
}