.redqueenDatePicker {
    &.arco-picker {
        border: 1px solid var(--Color-Border-Input-Default);
        background-color: var(--Color-Bg-Input-Subtler);
        box-shadow: 0px 2px 4px 0px rgba(10, 70, 89, 0.05);
        padding: 0 12px;
        padding-left: 4px;
        &:hover{
            border-color:var(--Color-Border-Input-Hovered);
            background-color: var(--Color-Bg-Input-Subtler);
        }
        &.arco-picker-focused{
            border-color:var(--Color-Border-Input-Pressed);
            .arco-picker-input-active input{
                background-color: var(--Color-Bg-Input-Subtler)!important;
            }
            &:hover{
                .arco-picker-input-active input{
                    background-color: var(--Color-Bg-Input-Subtler)!important;
                }
            }
        }
        .arco-picker input {
            color: var(--Color-Text-Neutral-Default);

            &::-webkit-input-placeholder {
                color: var(--Color-Text-Subtlest)
            }

            &::-moz-placeholder {
                color: var(--Color-Text-Subtlest)
            }

            &:-ms-input-placeholder {
                color: var(--Color-Text-Subtlest)
            }
        }
        .arco-picker-separator{
            width:12px;
            padding:0 8px;
            color:var(--Color-Icon-Subtler);
            font-size:16px;
            text-align:center;
        }
    }
}
.arco-picker-cell-today::after{
    background-color:var(--Color-Bg-Accent-Red-Bolder)!important;
}
.arco-picker-date-value{
    border-radius:2px!important;
}
.arco-picker-date-value{
    border-radius:2px!important;
}
.arco-picker-cell-range-start .arco-picker-date, .arco-picker-cell-hover-range-start .arco-picker-date{
    border-top-left-radius: 2px!important;
    border-bottom-left-radius: 2px!important;
}
.arco-picker-cell-range-end .arco-picker-date, .arco-picker-cell-hover-range-end .arco-picker-date{
    border-top-right-radius: 2px!important;
    border-bottom-right-radius: 2px!important;
}
.arco-picker-cell-in-range .arco-picker-date{
    background-color:var(--Color-Bg-Accent-Red-Subtlest)!important;
}
.arco-picker-cell:hover .arco-picker-date-value{
    background-color: var(--Color-Bg-Neutral-Subtlest-Hovered);
    // background-color:var(--Color-Bg-Accent-Red-Bolder)!important;
}
.arco-picker-cell-selected .arco-picker-date-value, .arco-picker-cell-selected:hover .arco-picker-date-value
{
    background-color:var(--Color-Bg-Accent-Red-Bolder)!important;
    border-radius:2px;
}
// .arco-picker-cell-selected .arco-picker-date-value, .arco-picker-cell-selected:hover .arco-picker-date-value
// {
//     background-color:var(--Color-Bg-Accent-Red-Subtlest)!important;}
