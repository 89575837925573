@import (reference) '@/style/public';

.arco-btn {

    &.redqueenButton>svg+span,
    &.redqueenButton>span+svg {
        margin-left: 0px;
    }

    &.redqueenButton {
        .flex(0);
        justify-content: center;
        // align-items: center;
        gap:6px;
        padding: 0 12px;

        span {
            height:100%;
            color: inherit !important;
        }
    }
    &.arco-btn-size-mini span{
        line-height:22px;
    }
    &.arco-btn-size-small span{
        line-height:26px;
    }
    &.arco-btn-size-default span{
        line-height:30px;
    }
    &.arco-btn-size-large span{
        line-height:34px;
    }
    &.arco-btn-size-small,
    &.arco-btn-size-mini {
        padding: 0 8px;
        font-size: 12px;

        span {
            font-size: 12px;
        }
    }

    &.redqueenButton {
        &.arco-btn-disabled{
            background: var(--Color-Bg-Disabeld, #EEF1F5);
            color: var(--Color-Text-Disabled, #AFB7C2);
        }
        &.arcoButtonGray:not(.arco-btn-disabled),&.arcoButtonGray:active:not(.arco-btn-disabled) {
            background-color: var(--Color-Bg-Neutral-Subtler-Default) ;
            color: var(--Color-Text-Subtle) ;
            border-color: var(--Color-Border-Accent-Neutral-Bolder);

            &:hover {
                background-color: var(--Color-Bg-Neutral-Subtler-Hovered)  ;
                border-color: var(--Color-Border-Accent-Neutral-Bolder);
                color: var(--Color-Text-Subtle)
            }
        }

        &.arcoButtonDefault:not(.arco-btn-disabled), &.arcoButtonDefault:active:not(.arco-btn-disabled) {
            background-color: var(--Color-Bg-Brand-Bold-Default) ;
            color: var(--Color-Text-Inverse) ;

            &:hover {
                background-color: var(--Color-Bg-Brand-Bold-Hovered) 
            }
        }

        &.arcoButtonWhite:not(.arco-btn-disabled), &.arcoButtonWhite:active:not(.arco-btn-disabled) {
            background-color: var(--Color-Bg-Neutral-Subtlest-Default);
            color: var(--Color-Text-Subtle) ;
            border-color: var(--Color-Border-Accent-Neutral-Bolder);

            &:hover {
                background-color: var(--Color-Bg-Neutral-Subtler-Hovered) ;
                border-color: var(--Color-Border-Accent-Neutral-Bolder);
                color: var(--Color-Text-Subtle) 
            }
        }

        &.arcoButtonDark:not(.arco-btn-disabled), &.arcoButtonDark:active:not(.arco-btn-disabled) {
            background-color: var(--Color-Bg-Neutral-Bold-Default) ;
            color: var(--Color-Text-Inverse);

            // border-color:var(--color-border-accent-neutral-bolder);
            &:hover {
                background-color: var(--Color-Bg-Neutral-Bold-Hovered) ;
                // border-color:var(--color-border-accent-neutral-bolder);
                color: var(--Color-Text-Inverse) 
            }
        }
        &.arcoButtonDark.removeHoverStyle:not(.arco-btn-disabled), &.arcoButtonDark.removeHoverStyle:active:not(.arco-btn-disabled) {
            &:hover {
                background-color: var(--Color-Bg-Neutral-Bold-Default) ;
                color: var(--Color-Text-Inverse);
            }
        }

        &.arcoButtonBlack:not(.arco-btn-disabled), &.arcoButtonBlack:active:not(.arco-btn-disabled) {
            background-color: transparent;
            color: var(--Color-Text-Neutral-Default);

            // border-color:transparent;
            &:hover {
                background-color: var(--Color-Bg-Neutral-Subtler-Hovered);
                color: var(--Color-Text-Neutral-Default);
                // color:var(--Color-Text-Inverse)
            }
        }
    }
}