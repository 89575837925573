.redqueenSwitch {
    &.arco-switch-type-round {
        background-color: #E0E4E9;

        &.arco-switch-checked {
            background:var(--Color-Bg-Accent-Red-Bolder);
        }
    }
    &.arco-switch[disabled]{
        background-color:var(--Color-Bg-Disabeld);
    }
    &.arco-switch[disabled].arco-switch-checked{
        background-color:#FFAFAC;
    }
}